/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, ResponseType } from 'axios';
import NProgress                           from 'nprogress';

import { ShopId }           from '@/types/globals';
import { scrollElemToTop }  from '@/utils/dom';
import {
    pushCartProductAddedEvent,
    pushConfiLoadEvent,
    pushMainNavEvent,
}                           from '@/utils/gtm';
import translate            from '@/utils/i18n/index';
import Logger, { LogLevel } from '@/utils/logger';

interface ConfiResponse {
    code: number;
    html: string;
    id: number;
    name: string;
    pageCount: number;
    dependencies: any;
    valueDependencies: any;
    customDependencies: any;
    priceInfo: any;
    preview: any;
    defaultParamsForSVG: any;
    parameterMatrix: any;
    productPromotions: any;
    validation: any;
    tax: string;
    isEdit: boolean;
    isCopy: boolean;
    isTemplate: boolean;
    campaign: any;
    windowType: any;
    openingType: any;
    isAdmin: boolean;
    attributeValueImages: any;
    productAttributeDescriptionImages: any;
    attributeValueGalleryImages: any;
    descriptionImagePath: string;
    productImagePath: string;
    shopSpecificProductImagePath: string;
    attributeValueImagePath: string;
    shopSpecificAttributeValueImagePath: string;
    shopSpecificDivisionImagePath: string;
    backgroundImagePath : string;
    currency: string;
    experimentalImageGeneration?: boolean;
    shopCode: string;
}

class Core {
    init() {
        this.initConfiguratorStarter();

        $('.modal').on('show.bs.modal', () => {
            document.documentElement.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
        }).on('hidden.bs.modal', () => {
            document.body.classList.remove('modal-open');
            document.documentElement.style.removeProperty('overflow');
        });

        // Configurator Startup via GET Parameter
        const urlParams = new URLSearchParams(window.location.search);

        const cid      = parseInt(urlParams.get('cid') || '0', 10);
        const template = urlParams.get('t') || '';
        const tag      = urlParams.get('v') || '';

        if (cid) {
            this.bootstrapConfigurator(cid, template, tag);
        }

        const mainNavBarElement = document.querySelector('.mainnav-bar') as HTMLDivElement | null;
        if (mainNavBarElement) {
            const navMainItems = mainNavBarElement.querySelectorAll('li span[data-fwd-toggle]') as NodeListOf<HTMLSpanElement>;
            const navSubItems = mainNavBarElement.querySelectorAll('span[data-fwd], a') as NodeListOf<HTMLSpanElement | HTMLAnchorElement>;

            navMainItems.forEach(element => {
                this.attachDataLayerClickEvent(element, element.innerText);
            });
            navSubItems.forEach(element => {
                const image = element.querySelector('img');
                let label = element.innerText;
                if (image) {
                    label = image.getAttribute('title') || image.alt;
                }

                this.attachDataLayerClickEvent(element, label);
            });
        }
    }

    private attachDataLayerClickEvent(element: HTMLElement, label: string) {
        element.addEventListener('click', () => { pushMainNavEvent(label); });
    }

    private initConfiguratorStarter() {
        // Configurator Startup via CSS Class
        const confiStarter = document.querySelector('.configurator-starter');

        if (confiStarter) {
            confiStarter.addEventListener('click', (event) => {
                const id = parseInt(confiStarter.getAttribute('rel-cid') || '0', 10);
                const t  = confiStarter.getAttribute('rel-template') || '';

                if (id === 0) {
                    Logger.log(LogLevel.ERROR, 'Could not start configurator. Attribute "rel-cid" is missing.');

                    return false;
                }

                event.preventDefault();
                this.bootstrapConfigurator(id, t, '');

                return false;
            });
        }
    }

    private buildConfiguratorLoader(loadingText: string) {
        return '<div class="configurator-loading-container"><img src="fileadmin/images/loader.gif" alt="Loader"><br>' +
            loadingText + '</div>';
    }

    private formatStartRequestParameters(parameters: { id: number, t: string, v: string }) {
        // Filters out keys with empty values, and converts values to strings
        const filteredParameters = Object.fromEntries(
            Object.entries(parameters)
                .filter(function(value) {
                    return value[1] + '';
                }),
        ) as { id?: string, t?: string, v?: string };

        return (new URLSearchParams(filteredParameters).toString());
    }

    private bootstrapConfigurator(productId: number, templateId = '', tag = '') {
        const configuratorContainer = document.getElementById('configurator-container');
        const contentContainer      = document.getElementById('content-container');
        const splashScreen          = document.querySelector('.splash-container') as HTMLElement | null;
        const params = {
            id: productId,
            t:  templateId,
            v:  tag,
        };

        const loadingText = translate('configuratorLoadingScreen', 'frontend');

        // check if dom elements exist
        if (!configuratorContainer || !contentContainer || !splashScreen) {
            console.log('ERROR: Could not start configurator. DIV containers are missing.');
            return false;
        }

        // clear all containers on this site
        contentContainer.innerHTML = this.buildConfiguratorLoader(loadingText);
        contentContainer.style.minHeight = '600px';

        const activeConfiguratorContainer = document.querySelector('.configurator-active > .container') as HTMLElement | null;
        if (activeConfiguratorContainer && activeConfiguratorContainer.style.position === 'fixed') {
            scrollElemToTop(activeConfiguratorContainer);
        } else {
            scrollElemToTop(document.body);
        }

        const action = 'start?' + this.formatStartRequestParameters(params);

        NProgress.start();

        this.post<ConfiResponse>(
            'configurator',
            action,
            params,
            (data) => {
                if (data.code > 0) {
                    this.initConfigurator(configuratorContainer, contentContainer, splashScreen, data);
                } else {
                    console.log('ERROR: Could not start configurator (' + data.code + ')');
                }
                NProgress.done();
                return false;
            },
            'json',
            (error) => {
                if (import.meta.env.DEV) {
                    Logger.log(LogLevel.ERROR, 'ERROR: ' + error);

                    return;
                }

                window.location.href = window.location.origin;
            },
        );

        return false;
    }

    private initConfigurator(
        configuratorContainer: HTMLElement,
        contentContainer: HTMLElement,
        splashScreen: HTMLElement,
        data: ConfiResponse,
    ) {
        pushConfiLoadEvent(data.id, data.name);
        configuratorContainer.innerHTML = data.html;
        this.initConfiguratorStarter();

        NProgress.inc(0.4);

        window.Configurator.name = data.name;
        window.Configurator.productId = data.id;
        window.Configurator.totalPages = data.pageCount;
        window.Configurator.dependencies = data.dependencies;
        // @ts-ignore
        window.Configurator.customDependencies = data.customDependencies;
        window.Configurator.parameterMatrix = data.parameterMatrix;
        window.Configurator.attributeValueImages = data.attributeValueImages;
        window.Configurator.descriptionImagePath = data.descriptionImagePath;
        window.Configurator.productImagePath = data.productImagePath;
        window.Configurator.shopSpecificProductImagePath = data.shopSpecificProductImagePath;
        window.Configurator.attributeValueImagePath = data.attributeValueImagePath;
        window.Configurator.shopSpecificAttributeValueImagePath = data.shopSpecificAttributeValueImagePath;
        window.Configurator.shopSpecificDivisionImagePath = data.shopSpecificDivisionImagePath;
        // @ts-ignore
        window.Configurator.productAttributeDescriptionImages = data.productAttributeDescriptionImages;
        // @ts-ignore
        window.Configurator.attributeValueGalleryImages = data.attributeValueGalleryImages;

        eval("(" + data.validation + ")");

        window.ConfiguratorController.init(
            data.shopCode,
            data.isEdit,
            data.isCopy,
            data.isTemplate,
            data.isAdmin,
        );

        NProgress.inc(0.4);

        // TODO: Replace jQuery here (change when confi JS is done in VueJS?)
        if(window.shopId === ShopId.HTDE) {
            $(splashScreen).fadeOut("fast", function() {
                scrollElemToTop(document.body);
            });
            $(configuratorContainer).show();
            $(contentContainer).hide();
        } else {
            $(splashScreen).slideUp("800",
                function() {
                    scrollElemToTop(document.body);
                    $(configuratorContainer).animate({ opacity: "1.0" },500);
                },
            );

            $(contentContainer).fadeOut(500);

            NProgress.inc(0.8);
        }

        NProgress.done(true);
    }

    post<T = any>(
        controller: string,
        action: string | number,
        params: Record<string, string | number | Record<string, string | number>>,
        onSuccess: ((data: T) => void) | undefined,
        responseDataType: ResponseType | undefined = undefined,
        onError: ((response?: unknown) => void) | undefined = undefined,
        requestDataType: string | undefined = undefined,
    ) {
        axios.post<T>(
            `${window.location.protocol}//${window.location.hostname}/${controller}/${action}`,
            params,
            {
                responseType: responseDataType || 'json',
                headers:      {
                    'Content-Type': requestDataType || 'multipart/form-data',
                },
            },
        )
            .then(response => {
                if (onSuccess) {
                    onSuccess(response.data);
                }
            })
            .catch((error: AxiosError) => {
                if (onError) {
                    onError(error);
                } else {
                    Logger.log(LogLevel.ERROR, 'Request could not be sent to the server: ' + error.message);
                }
            });
    }

    // noinspection JSUnusedGlobalSymbols
    toCart(productId: number, isConfigurator: boolean, params: Record<string, number|string>, qty: number) {
        if (isConfigurator) {
            this.post<{ code: number, fwd: string }>(
                'configurator',
                'save',
                { productId: productId, configuration: params },
                function(data: { code: number, fwd: string}) {
                    if (data.code === 1) {
                        if (window.lvl3GtmProductInfo) {
                            pushCartProductAddedEvent(
                                window.lvl3GtmProductInfo.productId,
                                window.lvl3GtmProductInfo.productName,
                                window.lvl3GtmProductInfo.options,
                                window.lvl3GtmProductInfo.price,
                                qty,
                            );
                        }

                        location.href = data.fwd;
                    } else {
                        console.log('could not put article into shopping cart.');
                    }
                },
                "json",
                undefined,
                'application/json',
            );
        } else {
            params.id = productId;
            params.qty = qty;
            this.post(
                'product',
                'save',
                params,
                function() {
                    if (window.lvl3GtmProductInfo) {
                        pushCartProductAddedEvent(
                            window.lvl3GtmProductInfo.productId,
                            window.lvl3GtmProductInfo.productName,
                            window.lvl3GtmProductInfo.options,
                            window.lvl3GtmProductInfo.price,
                            qty,
                        );
                    }

                    window.location.href = '/cart/';
                },
                'json',
            );
        }
    }

    // TODO: Remove if no longer needed
    // noinspection JSUnusedGlobalSymbols
    startConfigurator(productId: number) {
        return this.bootstrapConfigurator(productId);
    }
}

const instance = new Core();

export default instance;
window.Core = instance;
