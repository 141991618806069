/**
 * This script is responsible for tracking UTM parameters from the URL
 * and storing them as cookies for marketing analytics purposes.
 * It tracks the 'source', 'medium', 'campaign', 'content', and 'term'
 * parameters specifically - if present in the URL.
 *
 */

import { getCookie, setCookie } from '@/utils/cookies';
import Logger, { LogLevel }     from '@/utils/logger';

export class UtmTracking {
    public static readonly UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];

    private static getParameterByName(name: string): string {
        const match = window.location.search.match(new RegExp('[\\?&]' + name + '=([^&#]*)'));
        return match ? decodeURIComponent(match[1].replace(/\+/g, ' ')) : '';
    }

    private static setUtm(utmParam: string, utmVal: string): void {
        if (!getCookie(utmParam) && utmVal) {
            Logger.log(LogLevel.DEBUG, 'Set the cookie');
            setCookie(utmParam, utmVal);
        }
    }

    static init(): void {
        if (!window.location.search || !window.location.search.includes('utm_')) {
            return;
        }

        for (const param of UtmTracking.UTM_PARAMS) {
            const value = UtmTracking.getParameterByName(param);
            UtmTracking.setUtm(`utm[${param}]`, value);
        }
    }
}

UtmTracking.init();
