import { scrollElemToTop } from '@/utils/dom';

/**
 * Test for anchor links with target
 * add scroll offset to fragment target (if there is one)
 */
export default async function delayedFragmentTargetOffset(): void {
    setTimeout(() => {
        const targetEl = document.querySelector(':target');
        if (targetEl) {
            // Offset by height of fixed header
            scrollElemToTop(targetEl, -70);
        }
    }, 50);

    document.querySelectorAll('a[href*="#"]:not([href="#"]):not([data-attribute-noscroll="yes"])').forEach((anchor: HTMLAnchorElement) => {
        anchor.addEventListener('click', function(event: MouseEvent) {
            const target = document.querySelector(anchor.hash) ||
                document.querySelector(`[name=${anchor.hash.slice(1)}]`);

            if (
                window.location.pathname.replace(/^\//, '') === anchor.pathname.replace(/^\//, '') &&
                window.location.hostname === anchor.hostname &&
                target !== null
            ) {
                event.preventDefault();
                scrollElemToTop(target, -70);

                return false;
            }
        });
    });
}
