import axios, { AxiosError } from 'axios';

import { fadeIn }                    from '@/utils/dom';
import { pushNewsletterSignupEvent } from '@/utils/gtm';
import translate                     from '@/utils/i18n';
import getRoute                      from '@/utils/routing';

export default function initNewsletterHandling(): boolean {
    const newsletterContainers = document.querySelectorAll('.newsletter-cta');
    if (newsletterContainers.length === 0) {
        return false;
    }

    newsletterContainers.forEach(newsletterContainer => {
        const sendButton = newsletterContainer.querySelector('button') as HTMLButtonElement;
        const messageContainer = newsletterContainer.querySelector('.message') as HTMLDivElement;
        const emailField = newsletterContainer.querySelector('input[type="email"]') as HTMLInputElement;
        const termsField = newsletterContainer.querySelector('input[id^="footer_newsletter_terms"]') as HTMLInputElement;

        sendButton.addEventListener('click', () => {
            sendButton.disabled = true;
            messageContainer.classList.remove('error', 'success');
            emailField.classList.remove('error', 'success');
            termsField.classList.remove('error', 'success');

            if (termsField && !termsField.checked) {
                sendButton.disabled = false;
                messageContainer.innerHTML = translate('privacyRequired', 'validators');
                messageContainer.classList.add('error');
                fadeIn(messageContainer);
                termsField.classList.add('error');

                return;
            }

            axios.post(
                getRoute('api_v1_newsletter_signup'),
                {
                    email: emailField?.value,
                },
            )
                .then(response => {
                    sendButton.disabled = false;
                    messageContainer.innerHTML = response.data as string;
                    messageContainer.classList.add('success');
                    fadeIn(messageContainer);

                    pushNewsletterSignupEvent('footer');
                })
                .catch((error: AxiosError) => {
                    sendButton.disabled = false;
                    messageContainer.innerHTML = error.response.data as string;
                    messageContainer.classList.add('error');
                    fadeIn(messageContainer);
                    emailField.classList.add('error');
                });
        });

        emailField.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                sendButton.click();
            }
        });
    });

    return true;
}
