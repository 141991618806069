import { getCookie, setCookie } from "@/utils/cookies";

import Core from './core';

/* global blueimp */

function appInit() {
    Core.init();

    /*Bilder Lightbox*/
    const blueimpGallery = $('#blueimp-gallery');
    blueimpGallery.data('useBootstrapModal', false);
    blueimpGallery.data('youTubeClickToPlay', false);
    blueimpGallery.toggleClass('blueimp-gallery-controls', true);

    $('.lightbox-link').on('click', function(event) {
        const target = event.target,
              link = target.src ? target.parentNode : target,
              options = {index: link, event: event},
              links = this.getElementsByTagName('a');
        blueimp.Gallery(links, options);
    });
}

if (document.readyState === 'complete') {
    appInit();
} else {
    window.addEventListener('load', appInit);
}

/*Email decrypt function from typo3 */
window.decryptCharcode = function(n, start, end, offset) {
    n = n + offset;
    if (offset > 0 && n > end) {
        n = start + (n - end - 1);
    } else if (offset < 0 && n < start) {
        n = end - (start - n - 1);
    }
    return String.fromCharCode(n);
};

// decrypt string
window.decryptString = function(enc, offset) {
    let dec   = '';
    const len = enc.length;
    for (let i = 0; i < len; i++) {
        const n = enc.charCodeAt(i);
        if (n >= 0x2B && n <= 0x3A) {
            dec += window.decryptCharcode(n, 0x2B, 0x3A, offset); // 0-9 . , - + / :
        } else if (n >= 0x40 && n <= 0x5A) {
            dec += window.decryptCharcode(n, 0x40, 0x5A, offset); // A-Z @
        } else if (n >= 0x61 && n <= 0x7A) {
            dec += window.decryptCharcode(n, 0x61, 0x7A, offset); // a-z
        } else {
            dec += enc.charAt(i);
        }
    }
    return dec;
};

// decrypt spam-protected emails
window.linkTo_UnCryptMailto = function(s) {
    location.href = window.decryptString(s, -1);
};

// #NDT-2208 set equal height for pla header on two column view
window.setHeightPlaHeader = function() {
    if($('#lConHeight').length) {
        if ($(window).width() > 767) {
            const newMargin = $('#lConHeight .border').height() - $('#rConHeight').height() + 5;
            if (newMargin > 0) {
                $('#rConMargin').css('margin-bottom', newMargin);
            }
        }
    }
};

window.addRecruiting = function() {
    // Inhalt aus HTML datei am Anfang des Body-Tags ausgeben
    $.ajax({
        type:    "GET",
        url:     "fileadmin/assets/recruiting_ascii.html",
        success: function(text) {
            $('body').prepend(text);
        },
    });
    // Fensterversand.com Logo in Console ausgeben
    (function(url) {
        const image = new Image();

        image.onload = function() {
            const style = [
                'font-size: 1px;',
                'line-height: ' + this.height + 'px;',
                'padding: ' + this.height * .5 + 'px ' + this.width * .5 + 'px;',
                'background-size: ' + this.width + 'px ' + this.height + 'px;',
                'background: url('+ url +');',
            ].join(' ');

            console.log('%c', style);
            console.log("%cWir suchen Mitarbeiter: ", "font: 2em 'Open Sans',verdana,serif; color: black;");
            console.log("%chttps://fensterversand.com/jobs.php", "font: 2em 'Open Sans',verdana,serif; color: red;");
        };

        image.src = url;
    })('https://www.fensterversand.com/fileadmin/images/de/logos/fensterversand.png');
    // Text mit Hintergrundfarbe in Console ausgeben
};

$(function() {
    $('.teaser-bg').on('mouseenter', function() {
        $(this).animate({opacity: '1'});
    }).on('mouseleave', function() {
        $(this).animate({opacity: '0'});
    });

    window.addRecruiting();
    window.setHeightPlaHeader();
});

/*
 * All Shops | Mobile Navigation
 * Hotfix: NDT-1608
 */
$(function() {

    /* /menu item click event */

    /* show or hide certification */
    const certificateEl = $('.zertifikat');
    if (certificateEl.length > 0) {
        const cert = getCookie('cert');
        if (!cert) { //show certification
            certificateEl.removeClass('hide');
            // certificateEl.parent().addClass('cert-on');
        }
        $('.zertifikat > .close').on('click', function() { //hide certification
            setCookie('cert' , '1');
            certificateEl.addClass('hide');
            // certificateEl.parent().removeClass('cert-on');
        });
    }
});

/*
 * all sites | Anpassung am Typo3 Element für Teaser
 * Hotfix: NDT-1822
 */
$(window).on('load', function() {
// $(function() {
    const bgType4El = $('.bg-type4');
    if (bgType4El.length > 0) {
        const bg4            = bgType4El,
              bg4ImgElHeight = $('.bg-type4 img').css('height'),
              h              = parseInt(bg4ImgElHeight, 10) * parseInt(bg4.data('height'), 10) / 100,
              marginBottom   = parseInt(bg4ImgElHeight, 10) * (100 - parseInt(bg4.data('height'), 10)) / 100;
        bg4.css({
            'height':        h+'px',
            'margin-bottom': marginBottom+'px',
        });
    }

    if ($('.easy-wrap').length > 0) {
        setEasyWrapHeight();

        $(window).on('resize', function() {
            setEasyWrapHeight();
        });
    }
});

function setEasyWrapHeight() {
    $('.easy-wrap .type1:not(.redesign1) .border').each( function() {
        if ($(this).next('.link-button').length > 0 && /safari/i.test(navigator.userAgent)) {
            $(this).attr('style', '');
            $(this).attr('style', 'height:'+ $(this).parent().css('height'));
        }
    });
}


/*
* Ticket NDT-3566: make <img class="to-inline-svg" src="*.svg"/> to inline SVG
* Hotfix: NDT-3586
*/
const imgToInlineSvgEl = $('img.to-inline-svg');
if (imgToInlineSvgEl.length){
    imgToInlineSvgEl.each(function(){
        const $img   = $(this);
        const imgID   = $img.attr('id');
        const imgClass = $img.attr('class');
        const imgURL   = $img.attr('src');

        $.get(imgURL, function(data){
            let $svg = $(data).find('svg');
            if (typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            if (typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }
            $svg = $svg.removeAttr('xmlns:a');
            if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'));
            }
            $img.replaceWith($svg);
        }, 'xml');
    });
}

/* NDT-3907 replace document.write from TYPO3 */

const currentYear = new Date().getFullYear();

$(".currentYear").html(currentYear.toString());

$(window).on('resize', function() {
    $.each($('.resize-chart-container'), function(){
        resizeCharts(this);
    });
});

function resizeCharts(chartContainer){
    chartContainer = $(chartContainer);
    const parentWidth = chartContainer.parent().innerWidth();
    let chartElement, chartWidth, scale, left;
    if(chartContainer.children('div:first-child').length){
        chartElement = chartContainer.children('div:first-child');
        chartWidth   = chartElement.width();

        scale = Math.round(parentWidth/chartWidth*10)/10;
        left = (parentWidth-chartWidth)/2;

        chartElement.css('transform', 'scale('+scale+',1)').css('left', left+'px');
    }
}


// Configurator form of PLA pages
window.addEventListener("load", function() {
    const configuratorForm = document.querySelector("#configuratorForm.add-product-to-cart");
    if (configuratorForm) {
        document.getElementById('toCartBtn').disabled = false;
        function triggerToCart(event) {
            event.stopPropagation();
            event.preventDefault();

            const productId = document.getElementById("productId").value;
            Core.toCart(productId, true, configuration, 1);

            return false;
        }
        if ('ontouchend' in window) {
            configuratorForm.addEventListener("touchend",triggerToCart);
        } else {
            configuratorForm.addEventListener("submit",triggerToCart);
        }
    }
});

// Firefox Hack for Input Type Range - NDT-7023

$(document).on("wheel", ".gecko input[type='range']" , function() {
    $(this).trigger('blur');
});

// RED-126

const $readmoreContainer = $('.grid-container[data-read-more="true"]');
if ($readmoreContainer.length) {
    $(window).on("load", function() {
        $readmoreContainer.each(function() {
            const $this = $(this),
                  heightPercent = $(".grid-row", $this).data("height-var");

            $this.attr("data-height", $this.innerHeight());
            $this.attr("data-reduced-height", $this.innerHeight() * heightPercent);

            handleReadMoreContainer($this, false);

            $('.read-more-block button', $this).on('click', function() {
                handleReadMoreContainer($this, true);
            });
        });
    });
    /* Resize/Orientationchange*/
    $(window).on('orientationchange',function(){
        setTimeout( function() {
            $readmoreContainer.each(function() {
                const $this = $(this),
                      heightPercent = $(".grid-row", $this).data("height-var");

                $this.css('height','');
                $this.attr("data-height", $this.innerHeight());
                $this.attr("data-reduced-height", $this.innerHeight() * heightPercent);
                handleReadMoreContainer($this, false);
            });
        }, 50);
    });
}

function handleReadMoreContainer($this, changeState) {
    const dataMore = $(".read-more-block button", $this).data("more"),
          dataLess = $(".read-more-block button", $this).data("less"),
          fullHeight = parseInt($this.attr("data-height")),
          reducedHeight = parseInt($this.attr("data-reduced-height")),
          isOpen = $this.hasClass('open');

    if (changeState === true) {
        $this.animate({
            height: (isOpen ? reducedHeight : fullHeight),
        }, 300, function() {
            $('.read-more-block button', $this).text(isOpen ? dataMore : dataLess);
            $this.toggleClass('open', isOpen !== true);
        });
    } else {
        $this.css('height', isOpen ? fullHeight : reducedHeight);
    }
}

