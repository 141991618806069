import { fadeOut, fadeTo, smoothScroll } from '@/utils/dom';

function handleClick(): void {
    smoothScroll(0, 'top', 1000);
}

let backToTopIsVisible = false;
function toggleVisibility(): void {
    const shouldBeVisible = document.scrollingElement.scrollTop > 200;
    const backToTopEl = document.getElementById('backToTop-btn');

    if (shouldBeVisible && !backToTopIsVisible) {
        backToTopIsVisible = true;
        fadeTo(backToTopEl, .9, 'slow');
    } else if (backToTopIsVisible && !shouldBeVisible) {
        backToTopIsVisible = false;
        fadeOut(backToTopEl, 'fast');
    }
}

export default function initBackToTop(): void {
    const backToTopEl = document.getElementById('backToTop-btn');
    if (!backToTopEl) {
        return;
    }

    backToTopEl.addEventListener('click', handleClick);

    window.addEventListener('scroll', () => toggleVisibility());
}
