// Common libraries that should be loaded on all pages (main and landingpage entrypoints)

import initBackToTop               from './back-to-top';
import initNewsletterHandling      from './newsletter';
import delayedFragmentTargetOffset from './scroll-to-fragment';

function executeLibs() {
    delayedFragmentTargetOffset();
    initBackToTop();
    initNewsletterHandling();
}

if (document.readyState === 'complete') {
    executeLibs();
} else {
    window.addEventListener('load', () => executeLibs());
}
